@use '@angular/material' as mat;

$app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$app-accent:  mat.m2-define-palette(mat.$m2-light-blue-palette);
$app-warn:    mat.m2-define-palette(mat.$m2-red-palette);
$app-error:   mat.m2-define-palette(mat.$m2-red-palette);

$primary: mat.m2-get-color-from-palette($app-primary);
$primarylight: mat.m2-get-color-from-palette($app-accent, 600);
$primarydark: mat.m2-get-color-from-palette($app-primary, 800);
$accent: mat.m2-get-color-from-palette($app-accent);
$error: mat.m2-get-color-from-palette($app-error);

$warnfg: white;
$warnbg: $accent;

$errorfg: white;
$errorbg: red;

$primary-divider-color: lighten($primary, 20%);

$app-header-height: 42px;
$app-footer-height: 24px;

$page-header-height: 42px;
$page-header-height-double: 84px;
$page-footer-height: 42px;
