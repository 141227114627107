/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'barxui.theme.scss';
@import '_variables';
@import '_mixins';
@import '_fonts';

@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";
@import "~ag-grid-community/styles/ag-theme-material.css";

@import '~@syncfusion/ej2-base/styles/material.css';
/*
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
 */
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-pdfviewer/styles/material.css';
/*
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-notifications/styles/material.css';
 */

$default-config: mat.m2-define-typography-config();

.app {
  height: 100vh;
  overflow-y: hidden;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';

  .page {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'header header'
      'main panel'
      'footer footer';
    overflow: hidden;

    .mat-toolbar-row,
    .mat-toolbar-single-row {
      height: $page-header-height;
    }

    header {
      grid-area: header;
      height: $page-header-height;
      box-shadow: 0 0.1rem 0.15rem #ddd;
    }

    .sidepanel {
      grid-area: panel;
      width: 300px;
      border: 1px solid #ccc;
      height: calc(100vh - 42px - 24px - 84px);
      overflow-y: auto;
    }

    main {
      height: calc(100vh - $app-header-height - $app-footer-height - $page-header-height);
      max-height: calc(100vh - $app-header-height - $app-footer-height - $page-header-height);
      grid-area: main;
      overflow: auto;
    }

    main.notools {
      height: calc(100vh - $app-header-height - $app-footer-height);
    }

    main.nopad {
      padding: 0;
    }

    main.noscroll {
      overflow: hidden;
    }

    main.withfooter {
      height: calc(100vh - $app-header-height - $app-footer-height - $page-header-height - $page-footer-height);
    }

    footer {
      border-top: 1px solid #ccc;
      height: $page-footer-height;
      grid-area: footer;
      background: #999;
      color: rgba(0, 0, 0, .54);
      padding: 0;
    }
  }

  header {
    grid-area: header;
    height: $app-header-height;

    .mat-toolbar-row,
    .mat-toolbar-single-row {
      height: $app-header-height;
    }
  }

  main {
    grid-area: main;
    overflow: hidden;
  }

  footer {
    grid-area: footer;
    background: #3f51b5;
    color: white;
    padding: 0 0.5rem;
    height: $app-footer-height;

    a {
      color: white;
      font-weight: 500;
      text-decoration: none;
      line-height: 2em;
    }
  }
}

ag-grid-angular {
  flex: 1 0 100%;

  &.ag-theme-balham {
    .ag-root {
      border: 1px solid #d9dcde;;
    }

    .ag-root-wrapper {
      border: none;
    }

    .ag-row .ag-cell {
      display: flex;
      border: none;
      border-left: 1px solid #d9dcde;
      border-right: 1px solid #d9dcde;
      align-items: center;
    }

    .ag-cell-value {
      margin-left: 0 !important;
    }
  }

  &.ag-theme-balham,
  .ag-header-cell .ag-cell-label-container {
    font-family: mat.m2-font-family($default-config);
  }

  .cell-vertical-align-text-left {
    display: flex !important;
    align-items: center;
  }
}

@media (max-width: 800px) {

  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .tonarrow {
    display: none !important;
  }
}

.primary-color {
  color: $primary !important;
}

.accent-color {
  color: $accent !important;
}

html,
body {
  height: 100%;
  width: 100%
}

body {
  margin: 0;
  padding: 0;

  font-family: mat.m2-font-family($default-config);

  app-root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

* {
  box-sizing: border-box;
}

body.animated {
  background: linear-gradient(45deg, #f0f0f0, #aaaaaa, #cccccc, #bbbbbb);
  background-size: 400% 1100%;
  animation: gradient 25s ease infinite;
}

body.warehouse {
  background-color: #a7b8ca;
  backdrop-filter: blur(3px);

  background-image: url("/assets/home/warehouse.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body.printer {
  backdrop-filter: blur(3px);

  background-image: url("/assets/home/labelprinter.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.splash {
  margin: 0;
  padding: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  p {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }
}

.cc-banner {
  background-color: $primary !important;
  color: white !important;

  font: {
    family: mat.m2-font-family($default-config) !important;
    size: 11pt !important;
  };

  .cc-btn {
    border: none !important;
    font-size: inherit !important;
    background-color: darken($primary, 10%) !important;
  }

  .cc-link {
    color: white !important;
  }
}


.fill-space {
  flex: 1 1 auto;
}

.mat-mdc-card-content:last-child  {
  padding-bottom: 2px !important;
}

.mat-mdc-dialog-content {
  padding:8px !important;
}

.mat-mdc-form-field.skinny {
  width: 45px;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field.narrow {
  width: 65px;
}

.mat-mdc-form-field.mid {
  width: 85px;
}

.mat-mdc-form-field.small {
  width: 105px;
}

.mat-mdc-form-field.medium {
  width: 120px;
}

.mat-mdc-form-field.mediumlarge {
  width: 170px;
}

.mat-mdc-form-field.large {
  width: 200px;
}

.mat-mdc-form-field.larger {
  width: 260px;
}

.mat-mdc-form-field.wide {
  width: 350px;
}

.mat-mdc-form-field.wider {
  width: 400px;
}

.mat-mdc-form-field.verywide {
  width: 620px;
}

.mat-mdc-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-mdc-header-row,
.mat-mdc-row {
  width: 100%;
  display: flex;
}

.mat-mdc-dialog-title {
  margin: 0 0 5px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-mdc-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.helpDlg {

  .mat-mdc-dialog-container {
    padding: 0 0 24px 0;
    overflow: hidden;
  }

  .mat-mdc-dialog-actions {
    display: block;
    margin-left: 24px;
  }
}

mat-cell,
.mat-mdc-cell,
mat-footer-cell,
.mat-mdc-footer-cell,
mat-header-cell,
.mat-mdc-header-cell {
  display: flex;
  padding-right: 1em !important;
}

.propertyInput {
  border-radius: 3px;
  border-width: 1px;
  border-style: inset;
  border-color: rgba(0, 0, 0, 0.27);
  padding-left: 2px;
  padding-right: 2px;
  width: 94% !important;
  height: 22px !important;
  cursor: pointer;
  background-color: white;
}

.propertyInput.number {
  width: 60% !important;
}

/* for color dropdowns */
.colorbox {

  border: 0.5px solid #ccc;
  border-radius: 3px;
  height: 12px;
  width: 16px;
  margin: 0 2px 0 2px;
  cursor: pointer;
  background: none;

  &.empty {
    background: #ffffff !important;
  }

  .float {
    margin-right: -3em;
  }
}


.propertyErrorBorder {
  color: $primary;
  border-color: $error;
  background-color: $error;
}

.propertyErrorMessage {
  color: $error;
}

.highlight {
  color: $primary;
}

.warning {
  background: $accent;
  color: $primary;
}

.warningtext {
  color: $accent;
}

.errorText {
  color: $error !important;
}

fieldset {
  padding: 10px 10px 10px 0;
  border-radius: 4px;
}

legend {
  padding: 2px 4px;
  background: #fff;
}

fieldset > legend {
  float: left;
  margin-top: -20px;
}

fieldset > legend + * {
  clear: both;
}

.panel {
  background-color: whitesmoke !important;
  border-radius: 4px;
  border: 1px solid #ccc;
  height: $page-footer-height;
  padding: 5px;
  width: 100%;
}

.panelwarn {
  background-color: $warnbg !important;
  color: $warnfg;
  border-radius: 4px;
  border: 1px solid #ccc;
  height: $page-footer-height;
  padding: 0.25rem;
  margin: 0.25rem;
}

.select-larger.mat-mdc-select-panel {
  width: 400px !important;
}

.mat-mdc-option {
  min-height: 32px !important;
}

.mat-divider-vertical {
  height: 40px;
}

button span {
  @extend .mat-small;
}


.mat-toolbar-row,
.mat-toolbar-single-row {
  height: $page-header-height;
}

.mat-toolbar-multiple-rows {
  min-height: unset;
}

.toolbar-first-row {
  border-bottom: 1px solid #ccc;
}

.toolbar-second-row {

  mat-icon {
    font-size: 15pt;
    margin-right: 5px;
    width: unset;
    height: unset;
  }

  .status-indicator {
    font-size: 9pt;
    padding: 0 10px;
    line-height: 23px;
    border-radius: 100px;
    margin-right: 10px;

    mat-icon {
      font-size: 12pt;
      margin: 0;
      vertical-align: -4px;
    }
  }

  .results {
    font-weight: normal;
    font-size: 9pt;
    color: rgba(0, 0, 0, 0.54);
    margin-right: 1em;

    &.none {
      @extend .status-indicator;
      color: black;
      font-weight: 500;
      background-color: #ffd740;
    }
  }
}

.searchPanel {

  padding: 0 8px 0 0;

  span, label {
    @extend .mat-small;
  }

  //.mdc-checkbox {
  //  padding-right:0 !important;
  //}

  input[type=text] {
    border: 1px solid #ccc;
    -webkit-transition: 0.5s;
    border-radius: 3px;
    min-height: 32px;
    transition: 0.5s;
    outline: none;
    font-family: mat.m2-font-family($default-config);
    font-size:1.1rem;
  }

  input[type=text]:focus {
    border: 1px solid #555;
  }
}


.buttonbar {
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  height: 38px;

  button {
    min-width: 140px;
  }

  .selected {
    background-color: $primary !important;
    color: white !important;
  }
}


.mat-icon {
  height: 1.2rem;
}


.noMatFormControl {
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  border-radius: 3px;
  height: 32px;
  transition: 0.5s;
  outline: none;
}


.datalinkiconbutton {
  transform: scale(1.5);
  cursor: pointer;

  &.over {
    position: relative;
    left: -30px;
    opacity: 0.75;
  }

  &.small {
    transform: scale(1.8);
  }
}

