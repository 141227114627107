@use '@angular/material' as mat;
@import '_variables';

@include mat.all-component-typographies();
@include mat.core();

/* Create the theme object (a Sass map containing all of the palettes). */
$barxui-app-theme: mat.m2-define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warning: $app-warn),
      density: 0));

/*
 Include theme styles for core and each component used in your app.
 Alternatively, you can import and @include the theme mixins for each component
 that you are using.

 TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated
 @include mat.all-legacy-component-themes($barxui-app-theme);
*/

@include mat.all-component-themes($barxui-app-theme);
