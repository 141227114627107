@font-face {
  font-family: OCRB;
  src: url(/assets/fonts/ocrb4.ttf);
}

@font-face {
  font-family: moonhouse;
  src: url(/assets/fonts/moonhouse.ttf);
}

@font-face {
  font-family: "Arial";
  src: local("Arial"), url(/assets/fonts/arial.ttf);
}

@font-face {
  font-family: "Courier New";
  src: local("Courier New"), url(/assets/fonts/cour.ttf);
}

@font-face {
  font-family: "Impact";
  src: local("Impact"), url(/assets/fonts/impact.ttf);
}

@font-face {
  font-family: "Times New Roman";
  src: local("Times New Roman"), url(/assets/fonts/times.ttf);
}

@font-face {
  font-family: "Trebuchet MS";
  src: local("Trebuchet MS"), url(/assets/fonts/trebuc.ttf);
}

@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url(/assets/fonts/verdana.ttf);
}
